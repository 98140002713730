import {defineStore} from "pinia";
import {EnvStore} from "~/common-store/env-store";
import axios from "~/helpers/axios";
import {SUCCESS_CODE} from "~/consts/const";
import notify from "~/utils/notify";
import {LangStore} from "~/common-store/lang-store";


export const StationLetterStore = defineStore('station-letter-store-Hj89k', {
    state: () => ({
        stationLetterList: [],
        fetchStationLetterIng: false,
        stationLetterPageInfo: {
            pageSize: 10,
            currentPage: 1,
            total: 0,
        },
        unreadStationLetterList: [],
        fetchUnreadStationLetterIng: false,
        fetchUnreadStationLetterInterval: null,
    }),
    actions: {
        async init() {
            await this.fetchUnreadStationLetter()
            console.log(this.unreadStationLetterList)
            await this.fetchStationLetter()
            const tmp = this.stationLetterList
            if (tmp.length > 0) {
                for (let i = 0; i < tmp.length; i++) {
                    if (this.unreadStationLetterList.includes(tmp[i]['StationLetterId'])) {
                        tmp[i]['Read'] = false
                    } else {
                        tmp[i]['Read'] = true
                    }
                }
                this.stationLetterList = tmp
                // console.log(this.stationLetterList)
            }
        },
        async fetchUnreadStationLetter() {
            if (this.fetchUnreadStationLetterIng) {
                return
            }
            this.fetchUnreadStationLetterIng = true
            const conf = EnvStore().config;
            const langStore = LangStore()
            await langStore.loadLangInfoFromStorage()
            const langId = langStore.defaultLang
            await axios({
                method: "get",
                url: `${conf.ServerHost}/api/v1/merch/station-letter/unread-list`,
                params: {
                    langId: langId
                }
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }
                this.unreadStationLetterList = resp.data.Data
                console.log('站内信未读列表：', this.unreadStationLetterList)
            }).catch(e => {
                console.log(`${conf.ServerHost}/api/v1/merch/station-letter/unread-list`, e)
            }).finally(() => {
                this.fetchUnreadStationLetterIng = false
            })
        },
        async fetchStationLetter() {
            if (this.fetchStationLetterIng) {
                return
            }
            this.fetchStationLetterIng = true
            const conf = EnvStore().config;
            const langStore = LangStore()
            await langStore.loadLangInfoFromStorage()
            const langId = langStore.defaultLang

            await axios({
                method: "get",
                url: `${conf.ServerHost}/api/v1/merch/station-letter/list`,
                params: {
                    pageNumber: this.stationLetterPageInfo.currentPage,
                    pageSize: this.stationLetterPageInfo.pageSize,
                    langId: langId,
                }
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }
                this.stationLetterList = resp.data.Data.list
                this.stationLetterPageInfo.total = resp.data.Data.pager.total
            }).catch(e => {
                notify.err("yr5rlImRkX")
                console.log(`${conf.ServerHost}/api/v1/merch/station-letter/list`, e)
            }).finally(() => {
                this.fetchStationLetterIng = false
            })
        },
        async stationLetterPageChangeFunc(pageNumber, pageSize) {
            this.stationLetterPageInfo.currentPage = pageNumber
            await this.fetchStationLetter()
        },
    }
})
