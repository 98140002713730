import {defineStore} from 'pinia';
import {LanguageIcon} from "@heroicons/vue/24/outline";
import {NewspaperIcon} from "@heroicons/vue/20/solid";

export const MainLayoutStore = defineStore('main-layout-store-rbdhL', {
    state: () => ({
        sidebarOpen: false,
        breadcrumbs: [],
        menus: [
            {
                name: 'B6VMkDAAbY',
                href: '/merch-detail/page'
            },
            {
                name: 'Q0b62VFBZS',
                children: [
                    {
                        name: 'CNY钱包',
                        href: '/wallet/cny/page',
                    },
                    {
                        name: 'CNY钱包流水',
                        href: '/wallet/cny/bill/page',
                    },
                    {
                        name: 'USDT钱包',
                        href: '/wallet/usdt/page'
                    },
                    {
                        name: 'USDT钱包流水',
                        href: '/wallet/usdt/bill/page'
                    },

                ]
            },
            {
                name: 'KZunf1lhbt', tips: '',
                children: [
                    {
                        name: '0dljuP86Vl',
                        href: '/do/cny-do-list/page',
                    },
                    {
                        name: 'CNY补单列表',
                        href: '/do/cny-do-patch-list/page',
                    },
                    {
                        name: 'JDCPZG63Qp',
                        href: '/do/usdt-do-list/page',
                    },
                ]
            },
            {
                name: '5sapHTYk0o', tips: '',
                children: [
                    {
                        name: 'X5fdw96vH2',
                        tips: '',
                        href: '/pb/cny-pb-list/page',
                    },
                    {
                        name: 'Sf1k0pcFrd',
                        tips: '',
                        href: '/pb/usdt-pb-list/page',
                    },
                ]
            },
            {
                name: '7ql9L5Y4R0',
                children: [
                    {
                        name: '2VZSw4uKgw',
                        href: '/api/paramsPage',
                    },
                    {
                        name: 'WXoUDEoxos',
                        href: '/api/apiPage',
                    }
                ],
            },
        ],

    }),
    getters: {},
    actions: {
        parentMenuOpen(parentMenu) {
            const subMenus = parentMenu.children || [];
            if (subMenus.length < 1) {
                return false;
            }

            const route = useRoute();

            for (const subMenu of parentMenu.children) {
                //如果页面没有指定 上级, 执行自动匹配规则
                if (route.path === subMenu.href) {
                    //匹配成功 更新当前2级菜单
                    this.currentSubMenuName = subMenu.name
                    return true
                }
            }

            //手动指定 要打开的菜单
            if (!this.currentSubMenuName) {
                return
            }

            for (const subMenu of parentMenu.children) {
                parentMenu.open = true;
                if (this.currentSubMenuName === subMenu.name) {
                    return true
                }
            }

            return false;
        }
    }
});
