import {defineStore} from 'pinia';
import storage from "~/utils/storage";
import notify from "~/utils/notify";

const KEY_EDIT_ENABLE = 'merab_edit_enable';
const ENABLE_TOKEN = 'j4A8hEl8/x0b3UVWA4jRklTqc0/hzzo552JB0TrgvKg=';
//编辑模式
const EDIT_MODE_ENABLE = 1

export const EditModeStore = defineStore('editor-mode-store-AbdQj', {
    state: () => ({
        editModeEnableClickCounter: 0,
        editModeEnable: false,
        editModeEnableLoaded: false,
        editModeSelectModalVisible: false,
        userInputToken: ENABLE_TOKEN,
    }),
    actions: {
        editModeEnableClickCounterIncr() {
            this.editModeEnableClickCounter++;
            if (this.editModeEnableClickCounter >= 10) {
                this.editModeSelectModalVisible = true;
            }
        },
        async enable() {
            if (this.userInputToken !== ENABLE_TOKEN) {
                notify.err('秘钥错误')
                return
            }

            notify.success('编辑模式已开启')

            this.editModeEnable = true
            await storage.setItem(KEY_EDIT_ENABLE, EDIT_MODE_ENABLE);

            setTimeout(()=>{
                window.location.reload()
            },1000)
        },
        async disable() {
            notify.success('编辑模式已关闭')

            this.editModeEnable = false;
            await storage.removeItem(KEY_EDIT_ENABLE);

            setTimeout(()=>{
                window.location.reload()
            },1000)
        },
        async loadFromLocal() {
            if (!this.editModeEnableLoaded) {
                const editModeEnable = await storage.getItem(KEY_EDIT_ENABLE);
                console.log('editModeEnable',editModeEnable)
                this.editModeEnable = editModeEnable == EDIT_MODE_ENABLE
                this.editModeEnableLoaded = true;
            }
        },

    },
});
